const heroSliderData = [
  {
    title_1: "",
    title_2: " INFLUENCIA",
    title_3: " ",
    description: "Conectamos con tu comunidad digital",
    class: "center",
  },
];

export default heroSliderData;
